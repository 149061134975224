.section {

    &:first-child {
        margin-top: 5rem;
    }

    & + .section {
        margin-top: 4rem;

        @include media-breakpoint-up(md) {
            margin-top: 8rem;
        }
    }
}

// section header wrap
.section-header {
    margin-bottom: 2.5rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 4.5rem;
    }
}

.section-heading {
    font-size: 1.8rem;
    color: $blue;
    font-weight: 300;
    letter-spacing: -0.025rem;

    @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
    }
}

.section-heading-sm {
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: -0.025rem;

    @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
    }
}

.section-lead {
    @extend .lead;
    margin-top: 1.75rem;
    color: #888;

    @include media-breakpoint-up(lg) {
        font-size: 1.375rem;
    }
}

.app-demo .mobile-device {
    transition: transform 0.3s;
    @include media-breakpoint-up(lg) {
        transform: rotate(8deg);
    }
}

