.site-nav {
	border-bottom: 1px solid $gray-200;

	@include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
		background: rgba($white, 0.92);
        transition: background-color 0.25s 0.1s;
    }

    .menu-shown & {
        background: transparent;
        border-color: transparent;
        transition: 0s;
    }

    .container {
		position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
		align-items: center;

		@include media-breakpoint-up(lg) {
			padding: 2rem 0 0;
		}			
    }

    // logo
    .navbar-brand {
        width: 200px;
        height: 34px;
        padding: 0;
        transition: all 0.25s;

        @include media-breakpoint-up(lg) {
            width: 162px;
            height: 45px;
		}
		
		img {
			max-height: 100%;
			margin-right: 0.5rem;
		}
	}
	
    .navbar-nav {
        flex-direction: row;
    }

	// Hide following elements on smaller devices.
	// Will be replaced by mobile navigation instead.
	@include media-breakpoint-down(md) {
		.navbar-nav,
		.navbar-contact {
			display: none;
		}
	}

    .nav-item {
        display: flex;
        align-items: center;
    }

	// primary menu items
	.nav-item + .nav-item {
        margin-left: 0.75rem;

        @include media-breakpoint-up(xl) {
            margin-left: 1.5rem;
        }
	}

	.nav-link {
		padding: 0.5rem 1rem;
		font-weight: 600;
		transition: color 0.2s;
		font-size: 0.9375rem;
        user-select: none;
	}

	.nav-item .nav-link:hover {
		color: $blue;
	}

    .dropdown-toggle {
        // make room for the arrow
        padding-right: 2rem;

    	&::before, &::after {
    		/* this is the arrow inside the dropdown trigger*/
    		content: '';
    		display: block;
    		position: absolute;
    		top: 55%;
    		height: 1px;
    		width: 8px;
    		background: #AEAEAE;
    		backface-visibility: hidden;
    		transition: background-color 0.15s ease-in-out, width .15s ease-in-out, transform .15s ease-in-out;
    		border: 0;
    	}

    	&::before {
    		right: 16px;
    		transform: rotate(45deg);
    	}

    	&::after {
    		right: 11px;
    		transform: rotate(-45deg);
    	}
    }

    // dropdown is open
    .show .dropdown-toggle {
    	&::before, &::after {
    		/* transform the arrow into a 'X' */
    		width: 12px;
    	}

    	&::before {
    		/* transform the arrow into a 'X' */
    		transform: translateX(5px) rotate(-45deg);
    	}

    	&::after {
    		/* transform the arrow into a 'X' */
    		transform: rotate(45deg);
    	}
	}
	
	.btn.dropdown-toggle {
		@include media-breakpoint-up(lg) {
			font-size: 0.9375rem;
	
			&::before, &::after {
				background: $blue;
			}
		}
	}
	
	.btn.dropdown-toggle:hover,
	.show .btn.dropdown-toggle {
		&::before, &::after {
			background: #fff;
		}
	}

	.dropdown .dropdown-menu {
        position: absolute;
        visibility: hidden;
		display: block;
		opacity: 0;
		min-width: 10em;
        top: 130%;
		backface-visibility: hidden;
		transform: translateZ(0);
		transform: translateY(0.5rem);
		transition: opacity 0.2s 0s, visibility 0s 0.2s, transform 0.2s 0s;
        box-shadow: 0 40px 100px rgba(50,50,93,.1), 0 10px 35px rgba(50,50,93,.15), 0 5px 15px rgba(0,0,0,.1);
	}

	// right aligned dropdown menus
	.dropdown .dropdown-menu.dropdown-menu-right {
		right: 0;
		left: auto;

		&::before {
			right: 1rem;
			left: auto;
		}
	}

	// dropdown shown
	.dropdown.show .dropdown-menu {
		display: block;
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
		transition: opacity 0.2s 0s, visibility 0.2s 0s, transform 0.2s 0s;
	}

	.dropdown-item {
		font-size: 0.9375rem;
		padding: 0.8rem 1.3rem;

		&.active {
			color: $blue;
			background: transparent;
		}

		&:hover {
			color: $blue;
			background: transparent;
		}
	}
}

