body {
    @include media-breakpoint-down(md) {
        padding-top: 5rem;
    }
}


// no need for page gutters on smaller devices
.container {
    @include media-breakpoint-down(md) {
        max-width: 100%;
    }
}

.navbar {
 
    .navbar-brand,
    .navbar-brand:hover {
        color: $body-color;
    }
}

// make all images responsive by default
img {
    @extend .img-fluid;
    align-self: center;
    flex-shrink: 0; // IE 11 flex reset to avoid stretched images
}

.btn-cta {
    display: inline-flex;
    align-items: center;
    margin-top: 1.5rem;
    padding: 0.75rem 1.5rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.875rem;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10);
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: 0.015em;
}

.btn-icon {
    margin-left: 0.5rem;
}


.picture-wrapper {
    @extend .img-fluid;
    align-self: center;
    flex-shrink: 0; // IE 11 flex reset to avoid stretched images
    margin-bottom: 50px;
}


// make sure Safari doesn't change those phone link colors
a[href^=tel] {
    text-decoration:inherit;
    color: inherit;
}

.link-arrow-right {
    margin-left: 0.4rem;

    path {
        fill: $link-color;
    }
}

b {
    color: $gray-700;
}


.main-content {
	min-height: 500px;
}

.heading-icon {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto 1rem;
    flex: none;
}

.group-heading {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: -0.025em;

    @include media-breakpoint-up(lg) {
        font-size: 1.375rem;
    }
}

.group-heading--sm {
    margin-bottom: 0.8rem;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: -0.015em;
}

.page-footer {
    margin: 8rem 0 3.375rem;
}

.secondary-links .nav-link {
    color: $gray-600;
    font-size: 0.875rem;
}


.profile-img {
    height: 60px;
    margin-right: 1.25rem;
    border-radius: $border-radius;
}

.card {
    box-shadow: 0 2px 16px 0 rgba(57,63,98,.06);
}
