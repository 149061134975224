@import 'variables';
@import 'custom-bootstrap';

@import 'type',

        // navigation
        'nav-mobile',
        'nav-desktop',

        'sections',
        'cookie-consent',

        'syntax',
        'base';
